var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Dependencies } from "~/core/decorator";
import { Emit, Watch, Prop } from "vue-property-decorator";
import { StatusConfigService } from "~/services/manage-service/status-config.service";
import { State } from "vuex-class";
var AddCaseStatus = /** @class */ (function (_super) {
    __extends(AddCaseStatus, _super);
    function AddCaseStatus() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.loading = {
            state: false
        };
        _this.addModel = {
            principalId: "",
            principalName: "",
            code: "",
            type: "",
            color: "",
            name: "",
            sort: "",
            id: "",
            isImportant: "NO"
        };
        _this.addRules = {
            principalId: [{ required: true, message: "请选择委托方", trigger: "change" }],
            code: [{ required: true, message: "请输入案件编码", trigger: "blur" }],
            type: [{ required: true, message: "请选择状态类型", trigger: "change" }],
            color: [{ required: true, message: "请选择颜色", trigger: "change" }],
            sort: [{ required: true, message: "请选择优先级", trigger: "change" }],
            name: [{ required: true, message: "请输入名称", trigger: "blur" }],
            isImportant: [{ required: true, message: "请选择是否重点跟进", trigger: "change" }]
        };
        return _this;
    }
    AddCaseStatus.prototype.modifyDataChange = function (val) {
        // 如果传入id不为空，反显数据
        if (val.id !== "")
            this.addModel = val;
    };
    AddCaseStatus.prototype.close = function () {
        this.reset();
    };
    AddCaseStatus.prototype.success = function () {
        this.close();
    };
    Object.defineProperty(AddCaseStatus.prototype, "selectDisabled", {
        get: function () {
            return this.addModel.id ? true : false;
        },
        enumerable: false,
        configurable: true
    });
    /**
     * 表单重置
     */
    AddCaseStatus.prototype.reset = function () {
        this.addModel.id = "";
        var form = this.$refs["form"];
        form.resetFields();
    };
    /**
     * 取消
     */
    AddCaseStatus.prototype.cancel = function () {
        this.close();
    };
    /**
     * 表单提交
     */
    AddCaseStatus.prototype.submit = function () {
        var _this = this;
        // 文件ID
        var form = this.$refs["form"];
        form.validate(function (valid) {
            if (!valid)
                return;
            _this.loading.state = true;
            if (!_this.addModel.id) {
                var principal = _this.principalList.filter(function (e) { return e.id === _this.addModel.principalId; });
                _this.addModel.principalName = principal[0].name;
                _this.statusConfigService.addConfig(_this.addModel, _this.loading).subscribe(function (data) {
                    _this.success();
                    _this.$message.success("新增成功");
                });
            }
            else {
                _this.statusConfigService.modifyConfig(_this.addModel, _this.loading).subscribe(function (data) {
                    _this.success();
                    _this.$message.success("修改成功");
                });
            }
        });
    };
    __decorate([
        Dependencies(StatusConfigService)
    ], AddCaseStatus.prototype, "statusConfigService", void 0);
    __decorate([
        State
    ], AddCaseStatus.prototype, "principalList", void 0);
    __decorate([
        State
    ], AddCaseStatus.prototype, "colorList", void 0);
    __decorate([
        Prop()
    ], AddCaseStatus.prototype, "modifyData", void 0);
    __decorate([
        Watch("modifyData", { immediate: true })
    ], AddCaseStatus.prototype, "modifyDataChange", null);
    __decorate([
        Emit()
    ], AddCaseStatus.prototype, "close", null);
    __decorate([
        Emit()
    ], AddCaseStatus.prototype, "success", null);
    AddCaseStatus = __decorate([
        Component({
            components: {}
        })
    ], AddCaseStatus);
    return AddCaseStatus;
}(Vue));
export default AddCaseStatus;
